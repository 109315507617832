.coverImage {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderContainer {
    background: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.loader {
    border-radius: 0.01rem;
    border: 1px solid var(--color-white);
    background: var(--color-white);
    width: 50rem;
    height: 0.3rem;
    box-shadow: 25px 4px 75px rgba(0, 0, 0, 0.18);
    margin: 3rem;
}

.progress {
    background: var(--color-blue);
    height: 100%;
    border-radius: 0.4rem;
}
