.button {
    outline: none;
    border: none;
    color: var(--color-white);
    background: var(--color-blue);
    line-height: 1.045;
    font-size: 2.4rem;
    font-weight: 500;
    padding: 2rem 5rem;
    border-radius: 6.7rem;
    transition: background var(--duration-base) var(--easing-ease-out-quad);
}

.button:hover,
.button:focus {
    background: var(--color-alt-blue);
}
