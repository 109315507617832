.root {
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.frame {
    width: 100%;
    height: auto;
    object-fit: cover;
}
