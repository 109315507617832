.root {
    position: absolute;
    bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;
}

.title {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 115%;
    margin: 0 0 1rem;
}

.box {
    background: var(--color-white);
    box-shadow: 25px 4px 75px rgba(0, 0, 0, 0.18);
    display: flex;
    flex-wrap: wrap;
    width: auto;
    padding: 0.5rem;
    border-radius: 0.4rem;
}

.item {
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
}

.button {
    background: none;
    outline: none;
    padding: 0;
    border: none;
    width: 7rem;
    height: 7rem;
    pointer-events: all;
    background-size: cover;
}

.number {
    color: #6b8293;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.3rem;
    margin-top: 0.3rem;
}

@media (min-width: 768px) {
    .root {
        margin: 4.5rem;
        justify-content: flex-start;
        left: 0;
        z-index: 4;
    }
}
