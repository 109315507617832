.root {
    position: fixed;
    left: 0;
    top: 2rem;
    right: 0;
    bottom: 0;
    color: var(--color-blue);
    text-align: center;
    font-size: 3.2rem;
    z-index: 10;
    pointer-events: none;
}

.message {
    font-size: 2rem;
}

.copyEmbedCode {
    position: fixed;
    right: 2rem;
    bottom: 10.4rem;
    pointer-events: all;
    transition: background var(--duration-fast) var(--easing-ease-out-quad);
}

.copyEmbedCode.copied {
    background: var(--color-green);
}

.disableHotspots {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    pointer-events: all;
}
