.root {
    position: absolute;
    background: transparent;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity var(--duration-base) var(--easing-ease-out-quad);
    pointer-events: none;
    display: flex;
    padding: 3rem;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.18);
    z-index: 4;
}

.root.show {
    pointer-events: all;
    opacity: 1;
}

.box {
    position: relative;
    background: var(--color-white);
    box-shadow: 25px 4px 75px rgba(0, 0, 0, 0.18);
    padding: 2.5rem;
    max-width: 31.8rem;
    overflow-y: auto;
    max-height: 100%;
}

.title {
    color: var(--color-dark-text);
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 115%;
    letter-spacing: -0.01em;
    margin-bottom: 2rem;
    max-width: 90%;
}

.close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 1.215rem;
    height: 1.215rem;
    border: none;
    outline: none;
    transition: transform var(--duration-base) var(--easing-ease-out-quad);
    background: url('../../assets/images/close-blue.svg') no-repeat;
    background-size: 100%;
    cursor: pointer;
}

.close:hover,
.close:focus {
    transform: rotate(90deg);
}

.body {
    color: var(--color-light-text);
    font-family: var(--font-adelle-sans);
    font-size: 1.4rem;
    line-height: 175%;
    letter-spacing: -0.025em;
    margin-bottom: 1rem;
}

.body a {
    color: var(--color-alt-blue);
    text-decoration: none;
}

.mediaContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
}

.footnote {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    letter-spacing: -0.01em;
    align-items: flex-start;
    color: var(--color-light-text);
    font-family: var(--font-adelle-sans);
}

.footnoteNumber {
    margin-right: 1rem;
    font-weight: bold;
    font-size: 10px;
    line-height: 115%;
    padding-top: 0.3rem;
}

.footnoteText {
    font-size: 12px;
    line-height: 150%;
}

.media {
    width: 100%;
    height: auto;
}

.caption {
    padding: 1rem;
    background: #eef5f9;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: -0.025em;
    font-family: var(--font-adelle-sans);
    color: var(--color-light-text);
}

.resources {
    font-size: 1.4rem;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-weight: 500;
}

.resource {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    color: var(--color-alt-blue);
    text-decoration: none;
    font-weight: 400;
}

.resource img {
    margin-left: 0.5rem;
}

.resourcesTitle {
    color: var(--color-light-text);
}

@media (min-width: 768px) {
    .root {
        padding: 15rem;
        justify-content: flex-end;
        align-items: center;
    }
    .box {
        max-width: 37rem;
        padding: 3rem;
    }
    .close {
        top: 2rem;
        right: 2rem;
        width: 2rem;
        height: 2rem;
    }
}
