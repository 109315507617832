.root {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--duration-fast) var(--easing-ease-out-quad);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.4rem;
    font-weight: 500;
    -webkit-text-fill-color: var(--color-white);
    -webkit-text-stroke-color: var(--color-black);
    -webkit-text-stroke-width: 2.25px;
    background: transparent;
}

.root.show {
    opacity: 1;
}

.image {
    width: 5rem;
    height: 5rem;
    margin-right: 1.66rem;
}

@media (min-width: 768px) {
    .root {
        font-size: 5.7rem;
    }
    .image {
        width: 8.2rem;
        height: 8.2rem;
        margin-right: 2.5rem;
    }
}
