.button {
    position: absolute;
    outline: none;
    border: none;
    width: 3.4rem;
    height: 3.4rem;
    margin-left: -1.7rem;
    margin-top: -1.7rem;
    border-radius: 100%;
    background: var(--color-alt-blue) url('../../assets/images/plus.svg')
        no-repeat center;
    background-size: 1.26rem;
    z-index: 3;
    transition: all var(--duration-base) var(--easing-ease-out-quad);
}

.button.active {
    transform: rotate(45deg);
}

.button:hover,
.button:focus {
    background: var(--color-blue) url('../../assets/images/plus.svg') no-repeat
        center;
    background-size: 1.26rem;
}

@media (min-width: 768px) {
    .button {
        width: 5.2rem;
        height: 5.2rem;
        margin-left: -2.6rem;
        margin-top: -2.6rem;
        background-size: 2.15rem;
    }

    .button:hover {
        background-size: 2.15rem;
    }
}
