/* Color Variables */
:root {
    --color-white: #fff;
    --color-off-white: #f1f6fa;
    --color-black: #000;
    --color-green: #09921c;
    --color-blue: #3c91dd;
    --color-alt-blue: #0193e3;
    --color-dark-text: #15243c;
    --color-light-text: #6a8294;

    /* Font Variables */
    --font-circular-std: 'Circular Std', sans-serif;
    --font-adelle-sans: 'Adelle Sans', sans-serif;

    /* Base Styles */
    --base-font-size: 10px;
    --base-font-family: var(--font-circular-std);
    --base-font-weight: normal;

    /* Breakpoints */
    --breakpoint-medium: 768px;
    --breakpoint-large: 1024px;
    --breakpoint-extra-large: 1440px;
    --breakpoint-lock-content-width: 1640px;

    /* Animation Timing Functions */
    --easing-bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    --easing-ease: cubic-bezier(0.25, 0.1, 0.25, 1);
    --easing-ease-in: cubic-bezier(0.42, 0, 1, 1);
    --easing-ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    --easing-ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    --easing-ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    --easing-ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
    --easing-ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    --easing-ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
    --easing-ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
    --easing-ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    --easing-ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
    --easing-ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
    --easing-ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    --easing-ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    --easing-ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    --easing-ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    --easing-ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
    --easing-ease-out: cubic-bezier(0, 0, 0.58, 1);
    --easing-ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
    --easing-ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
    --easing-ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
    --easing-ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --easing-ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
    --easing-ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
    --easing-ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
    --easing-ease-out-back: cubic-bezier(0, 1, 0.4, 1.16);
    --easing-linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);

    /* Animation Durations */
    --duration-base: 300ms;
    --duration-fast: calc(var(--duration-base) * 0.5);
    --duration-slow: calc(var(--duration-base) * 1.5);
    --duration-super-slow: calc(var(--duration-base) * 2);
}

/* Typography */
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: var(--base-font-size);
    font-family: var(--base-font-family);
    font-weight: var(--base-font-weight);
}

button {
    all: unset;
    cursor: pointer;
}

[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

#root {
    width: 100%;
    height: 100%;
}

/* Font Face Definitions */
@font-face {
    font-family: 'Adelle Sans Sb';
    src:
        url('../assets/fonts/adelle/AdelleSans-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/adelle/AdelleSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Adelle Sans';
    src:
        url('../assets/fonts/adelle/AdelleSans-Bold.woff2') format('woff2'),
        url('../assets/fonts/adelle/AdelleSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Adelle Sans';
    src:
        url('../assets/fonts/adelle/AdelleSans-Italic.woff2') format('woff2'),
        url('../assets/fonts/adelle/AdelleSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Adelle Sans';
    src:
        url('../assets/fonts/adelle/AdelleSans-Regular.woff2') format('woff2'),
        url('../assets/fonts/adelle/AdelleSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Adelle Sans Sb';
    src:
        url('../assets/fonts/adelle/AdelleSans-SemiBoldItalic.woff2')
            format('woff2'),
        url('../assets/fonts/adelle/AdelleSans-SemiBoldItalic.woff')
            format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std';
    src:
        url('../assets/fonts/circular/CircularStd-Medium.woff2') format('woff2'),
        url('../assets/fonts/circular/CircularStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std Book';
    src:
        url('../assets/fonts/circular/CircularStd-Book.woff2') format('woff2'),
        url('../assets/fonts/circular/CircularStd-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std';
    src:
        url('../assets/fonts/circular/CircularStd-Bold.woff2') format('woff2'),
        url('../assets/fonts/circular/CircularStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std Book';
    src:
        url('../assets/fonts/circular/CircularStd-BookItalic.woff2')
            format('woff2'),
        url('../assets/fonts/circular/CircularStd-BookItalic.woff')
            format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std';
    src:
        url('../assets/fonts/circular/CircularStd-Black.woff2') format('woff2'),
        url('../assets/fonts/circular/CircularStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Circular Std Bold';
    src:
        url('../assets/fonts/circular/CircularStd-BoldItalic.woff2')
            format('woff2'),
        url('../assets/fonts/circular/CircularStd-BoldItalic.woff')
            format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std Medium';
    src:
        url('../assets/fonts/circular/CircularStd-MediumItalic.woff2')
            format('woff2'),
        url('../assets/fonts/circular/CircularStd-MediumItalic.woff')
            format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Circular Std Black';
    src:
        url('../assets/fonts/circular/CircularStd-BlackItalic.woff2')
            format('woff2'),
        url('../assets/fonts/circular/CircularStd-BlackItalic.woff')
            format('woff');
    font-weight: 900;
    font-style: italic;
}
