.root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    transition: opacity var(--duration-base) var(--easing-ease-out-quad);
    background: var(--color-white);
    display: flex;
    justify-content: center;
    align-content: center;
}

.root.open {
    opacity: 1;
    pointer-events: all;
}
