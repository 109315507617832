.button {
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
    outline: none;
    border: none;
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 100%;
    background: var(--color-off-white) url('../../../assets/images/close.svg')
        no-repeat center;
    background-size: 1.26rem;
    z-index: 3;
    transition: all var(--duration-base) var(--easing-ease-out-quad);
}

.button:hover {
    transform: rotate(90deg);
}

@media (min-width: 768px) {
    .button {
        top: 5.5rem;
        left: 5.5rem;
        background-size: 2.15rem;
        width: 5.2rem;
        height: 5.2rem;
    }
}
